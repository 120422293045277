<template>
  <div class="o-aplikaci">
    <Settings v-if="openSettings" v-on:close="closeSettingsWindow()"/>

    <div
        class="blur-content-animation"
        :class="{'blur-content': this.openSettings}"
    >

      <HeaderUser v-on:openSettings="openSettings = true;"/>

      <div class="o-aplikaci__text">
        <div class="column1">
          <h2>Program Osobního a Kvalifikačního Rozvoje (POKR)</h2>
          <p>Webová aplikace
            <strong>P</strong>rogram
            <strong>O</strong>sobního a
            <strong>K</strong>valifikačního
            <strong>R</strong>ozvoje
            (zkráceně <strong>POKR</strong>) slouží jako nástroj pro evidenci podpory tvůrčích aktivit pracovníků
            Fakulty informatiky a statistiky na Vysoké škole ekonomické v Praze. V jeho rámci je možné zažádat o podporu
            tvůrčích a pedagogických aktivit, a to nejdéle na období jednoho kalendářního roku.
          </p>
          <h2>Správné zobrazení aplikace</h2>
          <p>Aplikace POKR je plně optimalizována pro desktopové internetové prohlížeče Google Chrome, Mozila Firefox,
            Safari a Windows Edge. Aplikace není v současné době optimalizovaná pro prohlížeč Internet Explorer 9 a
            nižší. Responsibilní verze pro zobrazení na mobilním telefonu je prozatím spuštěna v beta verzi, která
            slouží zejména pro kontrolu vytvořených projektů a je testována pro iOS a Android.</p>
          <h2>Přihlášení do aplikace</h2>
          <p>Aplikaci POKR lze spustit na internetové adrese https://pokr.fis.vse.cz/. Na úvodní přihlašovací obrazovce
            se kromě popisu aplikace nachází tlačítko „Přihlásit se VŠE účtem“, které uživatele přesměruje na
            přihlašovací stránku pro oficiální aplikace VŠE (podobně jako například zam.vse.cz). Uživatel se ve výše
            zmíněné obrazovce přihlásí pomocí uživatelského jména a hesla, které používá v oficiálních aplikacích VŠE
            včetně InSIS. Uživatelské jméno je použito bez domény “@vse.cz”. Po úspěšném přihlášení bude uživatel
            přesměrován zpět do aplikace POKR.</p>
          <h2>První úspěšné přihlášení</h2>
          <p>Při prvním úspěšném přihlášení je nezbytné zvolit roli, kterou v současné době zastáváte a katedru, na
            které kmenově působíte (máte majoritní úvazek). Role odpovídá pracovnímu zařazení (doktorand, asistent,
            odborný asistent, docent nebo profesor). V případě, že uživatel zastává více rolí (například doktorand a
            asistent), vybere si jednu roli, kterou bude v rámci POKRu využívat.</p>

          <h2>Základní obrazovka</h2>
          <p>Práce s aplikací začíná na výchozí obrazovce, kam je uživatel přesměrován po přihlášení nebo po vyplnění
            role a katedry při prvním přihlášení do aplikace. Výchozí obrazovka funguje jako přehled programů, na
            kterých uživatel participuje. Při prvním přihlášení nejsou vložené žádné programy, ale pouze nabídka „Přidat
            program“. Přidané programy se po úspěšném vyplnění zobrazují v přehledu. U programů v přehledu je možné
            provádět operace nebo získávat informace dle jejich aktuálního statusu. Operace se provádějí prostřednictvím
            detailu programu, který je dostupný prostřednictvím ikon v pravé části popisku (nahlédnout, editovat,
            smazat, vložit soubor, vytisknout dokument). Programy na výchozí obrazovce jsou platné pouze pro aktuální
            kalendářní rok. Pro zobrazení programů z minulých období slouží odkaz “Historické programy”. Pro návrat na
            přehled v aktuálním roce slouží odkaz “Aktuální programy”. Pro zjištění více informací o specifikaci
            programu a jiných dalších parametrů lze prokliknout odkaz „O aplikaci“. V sekci “Finanční příspěvek” je
            zobrazen přehled potenciálních výkonnostních příplatků ke mzdě dle stavu programů. Stav programů nastavuje
            administrátor systému. Ikona s šipkou u jména slouží k odhlášení z aplikace.</p>
          <h2>Programy</h2>
          <p>Odkazem “Přidat program” dojde k založení nového programu osobního a kvalifikačního rozvoje. Pro založení
            programu slouží dialog, který navádí uživatele jednotlivými kroky k jeho vytvoření. Pro založení programu je
            nezbytné nejprve zvolit oblast, typ programu a dále nastavit všechny nezbytné parametry. Každý program
            (vyjma programu “Hostující profesor”) ukládá povinnost příslibu publikačního výstupu ve kvartilech dle AIS
            specifikovaných dle typu programu. Pro snazší výběr časopisů je vhodné využít přehledu dle FORDů, který
            naleznete na intranetu <a href="https://vse.sharepoint.com/sites/fis/tvurci-cinnost/SitePages/FORDy.aspx"
                                      target="_blank">FIS pro zaměstnance</a>.
            Pro založení programu je nutné vyplnit všechny parametry dle dialogu shora dolů. Před potvrzením tlačítkem
            „Potvrdit“ je nutné zvolit také alespoň jednu aktivitu z volby „Podpora osobního růstu“ - z nabídky
            checkboxů. Po úspěšném vytvoření programu budou vytvořené programy zaznamenané na obrazovce „aktuální
            programy“. Uvedený program lze zpětně upravovat, do doby, nežli bude administrátorem schválen nebo
            neschválen.
          </p>
          <h2>Statusy programů</h2>
          <p>Programy jsou barevně i slovně odlišeny na základě statusu v jakém se nacházejí. Status “Založený” znamená,
            že u programu zatím nebylo rozhodnuto o jeho schválení nebo neschválení. Založený program lze editovat nebo
            smazat. V rámci detailu programu, respektive dialogu pro editaci se může objevit zpráva od administrátora.
            Status “Schválený” znamená, že administrátor potvrdil program k realizaci a je možné v jeho detailu vložit
            soubor s publikačním výstupem nebo vytisknout dokument pro založení výkonnostního příplatku. Status
            “Zamítnutý” znamená, že program byl administrátorem zamítnut, což může být okomentováno zprávou od
            administrátora v detailu programu.</p>

          <h3 class="nadpis">Popis programů</h3>
          <p>Při přihlášení do programu zadejte oblast Vašich aktivit („Tvůrčí činnosti“ nebo „Pedagogika“), a potom
            pokračujte v zadání konkrétního programu.</p>
          <h2>Tvůrčí činnost</h2>
          <p>O podporu v rámci programu <strong>“Publikační činnost”</strong> na rok <strong>2025</strong> je možné
            žádat <strong>od 10. 2. do 25. 2. do 23:59.</strong> U ostatních programů celoročně. Podporu je možné pro
            tento rok získat v následujících programech spadajících do Tvůrčí činnosti:</p>
        </div>

        <div class="column2">
          <p>
            <strong>Publikační činnost</strong> - program je určen pro zájemce, kteří by chtěli podpořit výhradně svoje
            publikační aktivity. Umožní požádat o podporu pro zamýšlené publikace výhradně do časopisů, které jsou
            indexovány ve WoS a které měly AIS v roce 2022 v prvním až třetím kvartilu.
            Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů). Fakulta zjišťuje, ke které z vymezených
            fakultních domén se publikační výstup vztahuje:
            <br>

            <ol start="1" class="about">
              <li>Ekonomické aspekty stárnutí</li>
              <li>Demografické studie</li>
              <li>Lidský kapitál v souvislostech, financování vysokého školství</li>
              <li>Sociodemografické studie</li>
              <li>Statistické zachycení a modelování ekonomických procesů</li>
              <li>Optimalizační modely v podnikové sféře</li>
              <li>Analýza transakčních dat</li>
              <li>Speciální data v ekonometrii</li>
              <li>Sémantický web, ontologické inženýrství - vzory v ontologiích</li>
              <li>Aplikace datových věd</li>
              <li>Srozumitelnost a interpretovatelnost data miningových modelů</li>
              <li>Data Management (Správa dat)</li>
              <li>Dotazníková šetření v socioekonomické oblasti</li>
              <li>Modely hodnocení efektivnosti a výkonnosti</li>
              <li>Řízení a vývoj IS/ICT</li>
              <li>Aplikované prediktivní modely</li>
              <li>Sémantický web, ontologické inženýrství - znalostní grafy, grafové databáze, vizualizace grafových
                dat.
              </li>
              <li>Machine learning v cloudu a automatizace ML</li>
              <li>Analýza dezinformací v textech</li>
              <li>Efektivita multimediální komunikace (aplikace v marketingové komunikaci a prezentaci entit)</li>
              <li>Efektivita UX a UI</li>
              <li>Multimédia ve vzdělávání (video, grafika, podcast)</li>
              <li>Vícerozměrné metody</li>
              <li>Information Security</li>
              <li>Human-Computer Interaction (Interakce člověk-počítač)</li>
            </ol>
          </p>

          <p>Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu – zde se bude jednat o
            minimálně jednu položku.</p>


          <p>
            <strong>Vědecká monografie</strong> -
            podpořena bude celá publikace (ne jednotlivé kapitoly) monografického charakteru (splňuje podmínky
            monografie dle metodiky 25+) v prestižním zahraničním nakladatelství ve světovém jazyce. Podmínkou pro
            získání podpory je uzavřená smlouva s tímto nakladatelstvím.
          </p>


        </div>

        <div class="column2">
          <p>
            <strong>Jmenovací řízení</strong> -
            program slouží k podpoře přípravy uchazeče o akademickou hodnost profesor. Její součástí je podpora
            publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce 2023 v prvním či druhém
            kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů) a relevantní k oblasti jmenovacího
            řízení. Navíc musí předkladatel mít minimálně poloviční podíl na podporovaném článku.
            Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu – zde se bude jednat o
            minimálně jednu položku. Na konci roku předkládá pracovník jako další výstup kompletní dokumentaci k
            zahájení jmenovacího řízení.
          </p>


          <p><strong>Habilitační řízení</strong>- program slouží k podpoře přípravy uchazeče o hodnost docent. Její
            součástí je podpora publikace výhradně do časopisů, které jsou indexovány ve WoS a které měly AIS v roce
            2023 v prvním či druhém kvartilu. Časopisy musí být z fakultou podporovaných oblastí vědy (FORDů) a
            relevantní k oblasti habilitačního řízení. Navíc musí předkladatel mít minimálně poloviční podíl na
            podporovaném článku. Na konci roku předkládá pracovník jako další výstupy 80 % habilitační práce a
            dokumentaci k zahájení habilitačního řízení. Součástí programu je také možnost zaškrtnout si položky z
            podpory osobního růstu – zde se bude jednat o minimálně jednu položku.</p>


          <p>
            <strong>Hostující profesor</strong> -
            program slouží k podpoře internacionalizace FIS. Cílem je podpořit činnost zahraničních pedagogů na FIS s
            přesahem do vědecko-výzkumné činnosti. Ve formuláři žadatel vyplní informaci o působení hostujícího
            profesora a o jeho vědecko-výzkumných aktivitách na FIS. Na tyto činnosti mu bude přidělena finanční
            podpora. </p>


          <p><strong>Podpora podání nového externího projektu </strong>- program slouží k podpoře přípravy nového
            projektu, který bude podávat pracovník FIS (případně s kolektivem). Při podávání žádosti uchazeč vyplní
            název projektu, agenturu, k níž projekt chce podat, spolupracovníky (maximální počet pracovníků na projektu
            jsou čtyři) a jejich podíl na projektu. V tomto programu je možné zadat i žádost o podporu vědeckého týmu s
            uvedením konkrétních výstupů.
            Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu – zde se bude jednat o
            minimálně jednu položku.
          </p>


          <p><strong>Podpora podání opraveného externího projektu</strong> program slouží k podpoře přípravy již jednou
            podaného a neúspěšného projektu, který bude podávat pracovník FIS (případně s kolektivem). Při podávání
            žádosti uchazeč vyplní název projektu, agenturu, k níž projekt podává, spolupracovníky (maximální počet
            pracovníků na projektu jsou čtyři) a jejich podíl na projektu.
            Součástí programu je také možnost zaškrtnout si položky z podpory osobního růstu – zde se bude jednat o
            minimálně jednu položku.
          </p>

          <p>Všechny předložené návrh na podporu bude v první instnaci schvalovat vedoucí katedry.</p>
        </div>


        <!--
         <div class="column1">
              <h3 class="nadpis">Pedagogika</h3>
                <p><strong>Tvorba e-learningového modulu</strong> - Program slouží k rozvoji výuky prostřednictvím tvorby a aplikace e-learningového modulu, kterým může být kombinace vzdělávacího videa, komentovaného záznamu práce s aplikací, audio nahrávek, pracovních listů nebo jiných podpor, které zvýší efektivitu studia během přímé i nepřímé výuky. Pracovník se zadáním do systému POKR zavazuje k přípravě podkladů a účasti na realizaci. Technickou realizaci e-learningu (natáčení videa, audia, grafické práce apod.) zajistí Katedra multimédií, jejíž pověřený pracovník bude zároveň od okamžiku schválení projektu průběžně spolupracovat a konzultovat přípravu. Při podávání žádosti uchazeč vyplní studijní předmět, pro který by chtěl e-learningové podpory připravit a rámcovou představu o podobě e-learningového modulu. Součástí programu je účast na školeních pro různé dovednosti potřebné pro přípravu e-learningu.</p>
          </div>
        -->


        <div class="column1">
          <a
              class="buglist"
              href="https://docs.google.com/spreadsheets/d/1LN7NunG0X1EX51SYkmkrJVEdOXLwGBb8WMthnEMUAzY/edit?usp=sharing"
              target="_blank"
          >Buglist</a>
          <p class="verze">Verze aplikace 1.0.8.</p>
        </div>

        <div class="clearfix"></div>
      </div>

    </div>
  </div>
</template>

<script>
import HeaderUser from "./components/HeaderUser.vue";
import Settings from "./components/Settings.vue";

export default {
  name: "O_aplikaci",
  components: {
    HeaderUser,
    Settings
  },
  mounted() {
    this.$gtag.pageview({
      page_path: "/o"
    });
  },
  data() {
    return {
      openSettings: false
    }
  },
  methods: {
    closeSettingsWindow() {
      this.openSettings = false;
    }
  }
};
</script>
