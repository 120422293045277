<template>
  <modal
    :clickToClose="false"
    transition="nice-modal-fade"
    :delay="0"
    :width="'97%'"
    :height="'auto'"
    :scrollable="true"
    name="program-modal"
    overlayTransition="none"
  >
    <div class="program-modal-wrapper">
      <div
        class="modal_loading"
        v-if="showModal"
        :class="{nacteno: this.nacteno}"
      >
        <span></span>
      </div>

      <button
        @click="hideModal"
        class="program-modal-wrapper__close"
      ></button>

      <div
        class="program-modal-wrapper__financni_prispevek"
        :class="{ nacteno: this.nacteno }"
      >
        <p v-if="veda != 4 && veda != 7 && veda != 8">
          Finanční příspěvek:
          <strong>{{ cena }} Kč</strong>
          <!--prettyhtml-preserve-whitespace-->
          <span v-if="veda == 5 || veda == 6"> (Výši může upravit administrátor)</span>
        </p>
      
        <p v-else-if="this.edit && this.cena != 0 && (veda == 4 || veda == 7)">
          Finanční příspěvek:
          <strong>{{ cena }} Kč</strong>
          <!--prettyhtml-preserve-whitespace-->
          <span> (Výši může upravit administrátor)</span>
        </p>


        <p v-else>Výši finančního příspěvku doplní administrátor</p>
      </div>
      <div
        class="program-modal"
        :class="{ nacteno: this.nacteno }"
      >
        <div v-if="ov == 1 || ov == 3 || ov == 2">
          <div
            class="program-modal__errorbox"
            v-if="errors_program.length > 0"
          >
            <p
              v-for="error in errors_program"
              :key="error"
            >{{ error }}</p>
          </div>
        </div>





         <div v-if="this.edit != false">
                <div
                 v-if="this.disabled == true && this.ov != 3 && this.ov != 2 && !this.historicky"
                  class="vlozit_soubor"
                >
                  <div
                    class="vlozit_soubor__zaznam"
                    v-if="this.files != 0"
                  >
                    <a
                      v-for="fil in this.files"
                      :key="fil.nazev_souboru"
                      @click="nacteni_souboru_detail(fil.id)"
                      target="_blank"
                      class="vlozit_soubor__zaznam__item"
                    >
                      <div class="radek">
                        <p class="datum">{{ fil.datum }}</p>
                        <p class="popis">{{ fil.popis }}</p>
                      </div>

                      <p class="nazev">{{ fil.nazev_souboru }}</p>
                    </a>

                    <!--   <a v-for="fil in this.files" :key="fil.nazev_souboru" @click="ov=3" target="_blank" :href="'../file_user/' + fil.nazev_souboru" class="vlozit_soubor__zaznam__item" > <p class="nazev">{{fil.nazev_souboru}}</p> <p class="datum">{{fil.datum}}</p></a> -->
                  </div>

                  <button
                    @click="soubor_dialog()"
                    class="vlozit_soubor__button_vlozit_soubor">
                    <p v-if="veda == 1">Vložit článek/y</p>
                    <p v-if="veda == 2">Vložte přihlášku</p>
                    <p v-if="veda == 3">Vložte habilitační práci a přihlášku</p>
                    <p v-if="veda == 4 || veda == 7">Vložit poznámku o činnosti</p>
                    <p v-if="veda == 5 || veda == 6">Vložit text článku v PDF formátu</p>
                    <p v-if="veda == 8" >Vložit smlouvu s nakladatelem</p>

                  </button>
                </div>
              </div>



















 

        <div v-if="ov == 1">
          <div class="column1">
            <Info description="Oblast představuje zařazení programu podpory v rámci pracovních agend fakulty. Za každou oblast zodpovídá příslušný člen vedení fakulty." />
            <label
              for="oblast"
              class="program-modal__label"
            >Vyberte oblast</label>
            <div class="select-wraper">
              <select
                :disabled="disabled"
                @change="
                  error = '';
                  errors_program = [];
                  veda = '';
                "
                class="program-modal__select"
                id="oblast"
                v-model="oblast"
              >
                <option
                  value
                  disabled
                  hidden
                >Vyberte oblast</option>
                <option
                  v-for="oblast in oblasti"
                  :key="oblast.id"
                  :value="oblast.id"
                >{{ oblast.nazev }}</option>
              </select>
            </div>
          </div>








          <div
            id="validace-oblast"
            v-if="oblast == 1 || oblast == 2"
          >
            <div
              v-if="zobrazitConfirm"
              class="confirmation-dialog"
            >
              <div class="overlay"></div>
              <div class="dialog">
                <p>
                  V případě, že změníte volbu programu smažete Vaše současné
                  nastavení.
                </p>
                <button
                  class="ano"
                  @click="confirmSelection"
                >Ano, změnit</button>
                <button
                  class="ne"
                  @click="cancelSelection"
                >Ne, zanechat stávající</button>
              </div>
            </div>



 
            <div class="column1" v-if="oblast == 1">
              <Info description="Program představuje konkrétní podporovanou činnost." />
              <label
                for="program"
                class="program-modal__label"
              >Vyberte program</label>
              <div class="select-wraper">
                <select
                  :disabled="disabled"
                  @focus="showConfirmation_focus"
                  @change="showConfirmation"
                  class="program-modal__select"
                  id="program"
                  v-model="veda"
                >
                  <option
                    value
                    disabled
                    hidden
                  >Vyberte program</option>
                  <option
                    v-for="veda in vedy"
                    :key="veda.id"
                    :value="veda.id"
                    :hidden="veda.id==7"
                  >{{ veda.nazev }} {{ veda.popis }}</option>
                </select>
              </div>
            </div>

            <div class="column1" v-else-if="oblast == 2">
              <Info description="Program představuje konkrétní podporovanou činnost." />
              <label
                for="program"
                class="program-modal__label"
              >Vyberte program</label>
              <div class="select-wraper">
                <select
                  :disabled="disabled"
                  @focus="showConfirmation_focus"
                  @change="showConfirmation"
                  class="program-modal__select"
                  id="program"
                  v-model="veda"
                >
                  <option
                    value
                    disabled
                    hidden
                  >Vyberte program</option>
                  <option
                    v-for="veda in vedy"
                    :key="veda.id"
                    :value="veda.id"
                    :hidden="veda.id!=7"
                  >{{ veda.nazev }} {{ veda.popis }}</option>
                </select>
              </div>
            </div>








            <div class="column1">
              <div v-if="veda == 1"></div>

              <div
                v-if="veda == 7"
                class="program-modal__hostujici_profesor"
              >
                <Info description="Název a ident studijního předmětu, který bude e-learningem podpořen." />
                <label
                  for="predmety_nazev"
                  class="program-modal__label"
                >Předmět/y (název a ident)</label>
                <input
                  type="text"
                  :disabled="disabled"
                  name="predmety_nazev"
                  v-model="predmety_nazev"
                  placeholder="Předmět/y (název a ident)"
                />

                <Info description="Popis představy o podobě e-learningu (vzdělávací videa, audio nahrávky, komentované prezentace, komentované záznamy práce se softwarem, pracovní listy, simulační aplikace nebo jiné typy podpor) a dále popis představy o principu jejich využití ve výuce (například pro zpracování neměnných přednášek, které se přesunou do samostudia a pedagog se bude věnovat tématům, které vyžadují kontakt se studentem nebo pro doplnění teoretické látky o praktické příklady apod.)." />
                <label
                  class="program-modal__label"
                >Popis předpokládaného výstupu a jeho využití</label>
                <div class="program-modal__popis_projektu">
                  <textarea
                    rows="4"
                    cols="50"
                    :disabled="disabled"
                    placeholder="Popis předpokládaného výstupu a jeho využití"
                    :maxlength="popis_vystupu_vyuziti_znaky"
                    v-model="popis_vystupu_vyuziti"
                  ></textarea>
                  <div class="program-modal__popis_projektu__validace">
                    <p>Ještě můžete využít</p>
                    <p
                      class="p--znaky"
                      v-text="
                        popis_vystupu_vyuziti_znaky - popis_vystupu_vyuziti.length
                      "
                    ></p>
                    <p>znaků</p>
                  </div>
                </div>
                    
              
              </div>

              <div
                v-if="veda == 4"
                class="program-modal__hostujici_profesor"
              >
                <input
                  type="text"
                  :disabled="disabled"
                  name="jmeno-hostujiciho-profesora"
                  v-model="hostujici_profesor_nazev"
                  placeholder="Jméno hostujícího profesora"
                />
                <datepicker
                  :monday-first="mondayFirst"
                  :language="cs"
                  :disabled="disabled"
                  @input="formatovani_datepickeru"
                  v-model="hostujici_profesor_datumod"
                  :disabled-dates="disabledDatesFrom"
                  placeholder="Datum od"
                  @selected="updateDDT"
                ></datepicker>
                <datepicker
                  :monday-first="mondayFirst"
                  :language="cs"
                  :disabled="disabled"
                  @input="formatovani_datepickeru"
                  v-model="hostujici_profesor_datumdo"
                  :disabled-dates="disabledDatesTo"
                  @selected="updateDDF"
                  placeholder="Datum do"
                ></datepicker>

                <input
                  v-if="disabled != true"
                  type="text"
                  v-model="hostujici_profesor_cinnost_input.name_cinnosti"
                  placeholder="Uveďte činnost hostujícího profesora"
                  class="program-modal__hostujici_profesor__cinnosti__input"
                />

                <button
                  v-if="disabled != true"
                  @click="addHostujici_profesor_cinnost()"
                  class="program-modal__hostujici_profesor__cinnosti__pridat"
                >Přidat činnost</button>

                <div class="program-modal__hostujici_profesor__cinnosti">
                  <div
                    v-for="(h, index) in hostujici_profesor_cinnost"
                    :key="index"
                    class="program-modal__hostujici_profesor__cinnosti__item"
                  >
                    <p>{{ h.name_cinnosti }}</p>
                    <button
                      v-if="disabled != true"
                      class="program-modal__hostujici_profesor__cinnosti__item__smazat"
                      @click="deleteHostujici_profesor_cinnost(index)"
                    ></button>
                  </div>
                </div>
              </div>



     <div
                v-if="veda == 8"
                class="program-modal__hostujici_profesor"
              >
                <input
                  type="text"
                  :disabled="disabled"
                  name="nazev-knihy"
                  v-model="nazev_knihy"
                  placeholder="Název knihy"
                />

                 <input
                  type="text"
                  :disabled="disabled"
                  name="nakladatelstvi"
                  v-model="nakladatelstvi"
                  placeholder="Nakladatelství"
                 
                  />

              
                <datepicker
                  :monday-first="mondayFirst"
                  :language="cs"
                  :disabled="disabled"
                  @input="formatovani_datepickeru"
                  v-model="monogramie_datumdo"
                  :disabled-dates="disabledDatesTo"
                  @selected="updateDDF"
                  placeholder="Datum odevzdání"
                   class="datepicker-monografie"
                ></datepicker>

          
              </div>











              <div
                v-else-if="veda == 2 || veda == 3 || veda == 5 || veda == 6"
                class="program-modal__oblast"
              >
                <div v-if="veda == 3 || veda == 5 || veda == 6">
                  <div class="program-modal__datum">
                    <div v-if="veda == 3">
                      <label
                        for="datum"
                        class="program-modal__label"
                      >Název práce</label>
                    </div>
                    <div v-else>
                      <label
                        for="datum"
                        class="program-modal__label"
                      >Název projektu</label>
                    </div>

                    <input
                      :disabled="disabled"
                      type="text"
                      v-model="nazev_habilitacni_prace"
                      name="nazev_habilitacni_prace"
                      id="datum"
                      :placeholder="
                        veda == 5 || veda == 6
                          ? 'Název projektu'
                          : 'Název práce'
                      "
                    />
                  </div>
                </div>

                <div v-if="veda == 3 || veda == 2">
                  <Info description="Program představuje konkrétní podporovanou činnost." />
                  <label
                    for="oblast"
                    class="program-modal__label"
                  >Obor</label>
                  <div
                    v-if="nazevoboru.length > 1"
                    class="select-wraper"
                  >
                    <select
                      id="oblast"
                      class="program-modal__select"
                      :disabled="disabled"
                      v-model="nazev_oblasti"
                    >
                      <option :value="null">Vyberte obor</option>
                      <option
                        v-for="store in nazevoboru"
                        :key="store.id"
                        :value="store.id"
                      >{{ store.nazev }}</option>
                    </select>
                  </div>

                  <div class="program-modal__datum">
                    <label
                      for="datum_odevzdani"
                      class="program-modal__label"
                    >Předpokládaný termín odevzdání</label>
                    <datepicker
                      format="dd/MM/yyyy"
                      :disabled="disabled"
                      v-model="datum_odevzdani"
                      :value="datum_odevzdani"
                      @input="formatovani_datepickeru"
                      :language="cs"
                      id="datum_odevzdani"
                      name="datum_odevzdani"
                      placeholder="Předpokládaný termín odevzdání"
                    ></datepicker>
                  </div>
                </div>

                <div v-if="veda == 5 || veda == 6">
                  <label
                    for="agentura"
                    class="program-modal__label"
                  >Agentura</label>
                  <div
                    v-if="nazevoboru.length > 1"
                    class="select-wraper"
                  >
                    <select
                      id="agentura"
                      class="program-modal__select"
                      :disabled="disabled"
                      v-model="agentura"
                    >
                      <option :value="null">Vyberte agenturu</option>
                      <option
                        v-for="agentura in agentury"
                        :key="agentura.id"
                        :value="agentura.id"
                      >{{ agentura.nazev }}</option>
                    </select>
                  </div>

                  <div class="program-modal__spolupracovnik">
                    <div class="column1">
                      <div class="program-modal__spolupracovnik__label">
                        <Info description="Podíl vloženého spolupracovníka se odečítá z podílu předkladatele, vložit je možno maximálně 3 spolupracovníky" />
                        <label
                          for="pridat_punlikaci"
                          class="program-modal__label"
                        >Přidat spolupracovnický podíl</label>
                      </div>
                    </div>

                    <div class="column2">
                      <div class="program-modal__spolupracovnik__podilvlastni">
                        {{
                        $store.state.user.jmeno +
                        " " +
                        $store.state.user.prijmeni
                        }}: {{ podilAutor }} %
                      </div>

                      <div
                        v-for="spolupracovnik in spolupracovnici"
                        :key="spolupracovnik.jmeno"
                        class="program-modal__spolupracovnik__vypis"
                      >
                        <div class="program-modal__spolupracovnik__vypis__spolupracovnici">
                          <span>{{ spolupracovnik.jmeno }}</span>:
                          <span>{{ spolupracovnik.podil }} %</span>
                        </div>
                        <button
                          v-if="disabled != true"
                          @click="removeSpolupracovnik(spolupracovnik.id)"
                          class="program-modal__spolupracovnik__vypis__smazat"
                        ></button>
                      </div>
                    </div>

                    <div
                      v-if="disabled != true"
                      class="column2"
                    >
                      <div class="program-modal__spolupracovnik__radek">
                        <input
                          type="text"
                          placeholder="Jméno spolupracovníka"
                          v-model="spolupracovnikJmeno"
                          class="program-modal__spolupracovnik__input"
                        />
                        <input
                          type="number"
                          min="1"
                          :max="podilMax"
                          placeholder="podil"
                          class="program-modal__spolupracovnik__cislo"
                          v-model="spolupracovnikPodil"
                          @change="validateSpolupracovnikPodil()"
                        />
                      </div>

                      <button
                        class="program-modal__spolupracovnik__pridat"
                        @click="pridatSpolupracovnika"
                      >Přidat spolupracovníka</button>
                    </div>
                  </div>

                  <div class="program-modal__popis_projektu">
                    <textarea
                      rows="4"
                      cols="50"
                      :disabled="disabled"
                      placeholder="Popis projektu"
                      :maxlength="popis_projektu_znaky"
                      v-model="popis_projektu_text"
                    ></textarea>
                    <div class="program-modal__popis_projektu__validace">
                      <p>Ještě můžete využít</p>
                      <p
                        class="p--znaky"
                        v-text="
                          popis_projektu_znaky - popis_projektu_text.length
                        "
                      ></p>
                      <p>znaků</p>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="veda != 4 && veda != 7 && veda != 5 && veda != 6 && veda != '' && veda != 8"
                class="program-modal__pridat__publikaci"
              >
                <Info description="Dialog pro závazné vložení budoucích publikačních výstupů. Jejich zařazení je vymezeno dle FORDu." />
                <label
                  for="pridat_publikaci"
                  class="program-modal__label"
                >Přidat publikaci</label>
                <!--<button @click="hideModal" id="pridat_publikaci" class="program-modal__close"></button>-->
                <button
                  :disabled="disabled"
                  class="program-modal__button-pridat-publikace"
                  @click="ov = 2"
                >Přidat publikaci</button>

                <div
                  v-if="this.publikace"
                  class="program-modal__box"
                >
                  <div
                    v-for="p in this.publikace"
                    :key="p.id_parametr"
                    class="program-modal__box__item"
                  >
                    <div class="program-modal__box__publikace">
                      <span>
                        {{ p.casopis }} - {{ p.nazev_tematu }} -
                        {{ p.financni_ohodnoceni }},-
                      </span>
                     
                     
                    
                      <button
                        v-if="disabled != true"
                        @click="removePub(p.id_parametr)"
                        class="delete"
                      ></button>
                    


                      <button
                        @click="editPub(p)"
                        class="edit"
                      ></button>
                    </div>
                  </div>
                </div>



      



              </div>

              <div v-else></div>

              <div
                v-if="veda != '' && veda != 4 && veda != 7 && veda != 8 "
                class="program-modal__volitelne"
              >
                <div class="nadpis">Vybráno {{ selectedv }}/{{ volitelne.length }}</div>

                <h4 class="nadpis-h4">Podpora osobního růstu</h4>
                <div class="checkbox">
                  <div
                    class="column3"
                    :key="v.id"
                    v-for="v in volitelne"
                  >
                    <div
                      v-if="v.id == 1"
                      class="hodnota_checkbox"
                    >
                      <Info description="Kurz Academic writing – na základě požadavků bude zajištěn kurz buď na VŠE, nebo bude objednán u externí společnosti. V případě úspěšného absolutoria kurz bude hrazen z prostředků FIS." />
                    </div>
                    <div
                      v-if="v.id == 2"
                      class="hodnota_checkbox"
                    >
                      <Info description="Certifikát z AJ – Jedná se o kurz, který bude poskytnut výhradně katedrou anglického jazyka VŠE v minimální úrovní B2. Poplatek za kurz bude uhrazen z prostředků FIS při úspěšném absolutoriu kurzu." />
                    </div>
                   <!-- <div
                      v-if="v.id == 3"
                      class="hodnota_checkbox"
                    >
                      <Info description="Školení pro tvorbu e-learningu - Školení připraví pracovníka na využití a tvorbu vhodných e-learningových podpor pro kurzy prezenčního i distančního studia. V rámci školení je možné získat znalosti a dovednosti od koncepce zapojení do výuky, přes přípravu podkladů až po technickou výrobu vlastních podpor. Školení je rozloženo v kalendářním roce po 4 hodinových modulech v celkovém rozsahu 40 h." />
                    </div>
                  -->
                  
                  
                    <div
                      v-if="v.id == 4"
                      class="hodnota_checkbox" style="margin-top: -40px"
                    >
                      <Info description="Kurz pro práci s kvalitou hlasu, jeho kultivací a technickými aspekty, které pomohou v prezentaci vědeckých výstupů, profesních jednáních i běžném životě." />
                    </div>
                   
                   
                   
                   <!--
                    <div
                      v-if="v.id == 5"
                      class="hodnota_checkbox"><Info description="Výběr z programu školení anglické verze IGA s tématy: Příprava projektu IGA/A, Příprava vědeckého projektu pro začátečníky (IGA, Erasmus+, TAČR a GAČR), Výběr správného časopisu pro zaslání článku, Academic Writing, Kvalitativní metody výzkumu I, Kvantitativní metody výzkumu I, Příprava a řízení mezinárodní vědeckého projektu (IGA, Erasmus+, TAČR a GAČR), Kvalitativní metody výzkumu II, Kvantitativní metody výzkumu II." />
                    </div>

-->


                    <div
                      v-if="v.id == 6"
                      class="hodnota_checkbox"
                    >
                    <Info description="Návrh vlastní podpory." />
                    </div>


                    <label :for="v.id" v-if="v.id != 3 && v.id != 5">{{ v.nazev }}</label>
                    <input
                      :disabled="disabled"
                      type="checkbox"
                      :value="v.id"
                      :id="v.id"
                      v-model="v.selected"
                      v-if="v.id != 3 && v.id != 5"
                    />


                  </div>
                </div>

                <div
                  class="navrh_vlastni_podpory"
                  v-if="this.volitelne[5].selected == true"
                >
                  <h4>Návrh vlastní podpory</h4>

                  <Info description="Popis návrhu vlastní podpory." />
                  <label
                    for="popis_navrhu"
                    class="program-modal__label"
                  >Popis návrhu vlastní podpory</label>
                  <input
                    v-model="popis_navrhu"
                    id="popis_navrhu"
                    type="text"
                    name="popis_navrhu"
                    placeholder="Uveďte návrh vlastní podpory"
                  />

                  <Info description="Odhadovaná částka." />
                  <label
                    for="odhadovana_cena_navrhu"
                    class="program-modal__label"
                  >Odhadovaná částka</label>
                  <input
                    v-model="odhadovana_cena_navrhu"
                    id="odhadovana_cena_navrhu"
                    type="number"
                    name="odhadovana_cena_navrhu"
                    placeholder="Uveďte odhadovaný náklad na realizaci podpory"
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
                  />
                </div>

              </div>

          
          
          
               <div class="zamitnuti_podpory" v-if="veda != '' && veda == 1 || veda == 3 || veda == 2 "> 
               <p>Podpora nebude přidělena uchazečům, kteří v uplynulých 3 letech žádali v rámci POKRu o příslušný kvartil a nenaplnili ho.</p>
               </div>



              <div class="clearFix"></div>

              <div
                class="program-modal__administrator"
                v-if="messages.length > 0"
              >
                <div class="program-modal__nadpis-komunikace">Komunikace s administrátorem</div>
                <div id="chat">
                  <div id="chat_div">
                    <div id="messages-window">
                      <div
                        v-for="message in messages"
                        :key="message"
                        v-bind:class="
                          'message ' +
                            (message.id_uzivatele ==
                            $store.state.user.id_uzivatel
                              ? 'vlastni'
                              : 'cizi')
                        "
                      >
                        <div class="zprava_div">
                          <span
                            class="user_img"
                            v-if="
                              message.id_uzivatele !=
                                $store.state.user.id_uzivatel
                            "
                          ></span>

                          <div class="zprava_sekce">
                            <span
                              class="uzivatel"
                              v-if="
                                message.id_uzivatele ==
                                  $store.state.user.id_uzivatel
                              "
                            >Vy</span>
                            <span
                              class="uzivatel"
                              v-else
                            >
                              {{
                              message.cele_jmeno
                              }}
                            </span>

                            <div class="zprava">{{ message.content }}</div>
                          </div>

                          <span
                            class="user_img"
                            v-if="
                              message.id_uzivatele ==
                                $store.state.user.id_uzivatel
                            "
                          ></span>

                          <div class="clearFix"></div>
                        </div>

                        <div class="clearFix"></div>
                      </div>
                    </div>
                  </div>

                  <input
                    type="text"
                    v-model="nova_zprava"
                  />

                  <button v-on:click="addMessage">Odeslat zprávu</button>
                </div>
              </div>
            </div>
          </div>

         

          
       
          <button
            v-if="disabled != true"
            class="program-modal__confirm"
            @click="confirm"
          >Potvrdit</button>
        </div>





        <div
          v-if="ov == 2"
          class="program-modal__parametr"
          id="parametr_box"
        >
          <div>
            <div class="program-modal__parametr__Q">
              <div
                v-for="i in q"
                :key="i.type"
                :class="'column' + q.length"
              >
                <div
                  v-if="i.type == 1"
                  class="hodnota_q"
                >
                  <Info description="Zařazení publikace v 1. kvartilu daného FORDu dle AIS." />
                </div>
                <div
                  v-if="i.type == 2"
                  class="hodnota_q"
                >
                  <Info description="Zařazení publikace v 2. kvartilu daného FORDu dle AIS." />
                </div>
                <div
                  v-if="i.type == 3"
                  class="hodnota_q"
                >
                  <Info description="Zařazení publikace v 3. kvartilu daného FORDu dle AIS." />
                </div>

                <input
                  type="checkbox"
                  :id="i.type"
                  :disabled="disabled"
                  v-model="i.selected"
                  @click="selectq(i.type)"
                />

                <label :for="i.type">Q{{ i.type }}</label>
              </div>
            </div>

            <div class="program-modal__parametr__range">
              <div class="autorsky_podil__info column1">
                <Info description="Autorský podíl autora na budoucí publikaci." />
              </div>

              <div class="column2 autorsky_podil">
                <label for="range">
                  Autorský podíl:
                  <span>{{ podil | formatNumber }}</span>
                </label>
              </div>

              <div class="column2">
                <input
                  id="range"
                  :disabled="disabled"
                  type="range"
                  :min="minpodil"
                  max="1"
                  step="0.01"
                  v-model="podil"
                />
              </div>
            </div>

            <Info description="Předběžný název tématu." />
            <label
              for="nazev_tematu"
              class="program-modal__label"
            >Předběžný název tématu</label>
            <input
              v-model="nazev_tematu"
              id="nazev_tematu"
              type="text"
              :disabled="disabled"
              name="nazev"
              placeholder="Uveďte předběžý název tématu"
            />

            <Info description="Název časopisu." />
            <label
              for="nazev_casopisu"
              class="program-modal__label"
            >Název časopisu</label>
            <input
              v-model="casopis"
              id="nazev_casopisu"
              type="text"
              :disabled="disabled"
              name="casopis"
              placeholder="Uveďte název časopisu"
            />

            <Info description="Field of Research and Development." />
            <label
              for="ford"
              class="program-modal__label"
            >FORD</label>
            <div
              v-if="fordy.length > 1"
              class="select-wraper"
            >
              <select
                class="program-modal__select"
                :disabled="disabled"
                id="ford"
                v-model="ford"
              >
                <option :value="null">Vyberte FORD</option>
                <option
                  v-for="fo in fordy"
                  :key="fo.id_ford"
                  :value="fo.id_ford"
                >{{ fo.hodnota }}</option>
              </select>
            </div>


            <Info description="Doména, pod kterou spadá publikace" />
            <label
                for="domena"
                class="program-modal__label"
            >Doména</label>
            <div
                v-if="domeny.length > 1"
                class="select-wraper"
            >
              <select
                  class="program-modal__select"
                  :disabled="disabled"
                  id="domena"
                  v-model="domena"
              >
                <option :value="null">Vyberte Doménu</option>
                <option
                    v-for="fo in domeny"
                    :key="fo.id_domena"
                    :value="fo.id_domena"
                >{{ fo.hodnota }}</option>
              </select>
            </div>
          </div>

          <button
            class="program-modal__button__zpet"
            @click="parametr_zpet()"
          >Zpět</button>
       
       
     
          <button
            class="program-modal__button__ulozitpublikaci"
            @click="pridatP()"
            v-if="disabled != true"
          >Potvrdit</button>
         
        </div>




        <div
          v-if="ov == 3"
          id="file_box"
          class="program-modal__dokument"
        >
          <h2 v-if="veda == 1">Vložit článek/y</h2>
          <h2 v-if="veda == 2">Vložte přihlášku</h2>
          <h2 v-if="veda == 3">Vložte habilitační práci a přihlášku</h2>
          <h2 v-if="veda == 4">Poznámka o činnosti</h2>
          <h2 v-if="veda == 5 || veda == 6">Text článku v PDF formátu</h2>

          <label for="dokument_popis">Popis dokumentu</label>
          <input
            type="text"
            name="popis_dokument"
            v-model="popis_soubor"
            :maxlength="this.popis_soubor_znaky"
            id="popis_dokument"
            placeholder="Popis souboru"
          />

          <div class="program-modal__dokument__soubor__validace">
            <p>Ještě můžete využít</p>
            <p
              class="p--znaky"
              v-text="this.popis_soubor_znaky - this.popis_soubor.length"
            ></p>
            <p>znaků</p>
          </div>

          <div
            v-if="file_novy_nazev.length"
            class="program-modal__dokument__soubor"
          >{{ this.file_novy_nazev }}</div>

          <div
            v-if="!file_edit"
            class="upload-btn-wrapper"
          >
            <button class="btn">
              <span v-if="file_novy_nazev.length">Změnit soubor</span>
              <span v-else>Vybrat soubor</span>
            </button>
            <input
              type="file"
              id="file-upload"
              class="file"
              ref="file"
              accept="application/pdf"
              v-on:change="onChangeFileUpload()"
            />
          </div>



    


          <button
            v-if="file_edit"
            v-on:click="upravitForm()"
            class="program-modal__confirm program-modal__confirm--file"
          >Upravit</button>
          <button
            v-else
            v-on:click="submitForm()"
            class="program-modal__confirm program-modal__confirm--file"
          >Potvrdit</button>




          <button
            class="program-modal__button__zpet"
            @click="parametr_zpet()"
          >Zpět</button>
        </div>





      </div>
    </div>
  </modal>
</template>

<script>
/*
IMPORT KOMPONENTY
import Test from './Test.vue'
*/
import Datepicker from "vuejs-datepicker";
//čeština v kalendáři
import { cs } from "vuejs-datepicker/dist/locale";

//generování náhodných id
import { uuid } from "vue-uuid";
import moment from "moment";
import Info from "./Info.vue";

var numeral = require("numeral");

export default {
  name: "programModal",
  components: {
    Info,
    Datepicker,
  },

  props: {
    edit: Boolean,
    id: Number,
    historicky: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      mondayFirst: true,
      nacteno: false,
      error: "",
      programy: [],
      disabled: false,
      oblasti: [],
      vedy: [],
      agentury: [],
      volitelne: [],
      fordy: [],
      domeny: [],
      nazevoboru: [],
      oblast: "",
      veda: "",
      agentura: null,
      hostujici_profesor_datumod: "",
      hostujici_profesor_datumdo: "",

      //soubor
      file: null,
      files: [],
      file_novy_nazev: "",
      popis_soubor: null,
      files_id: "",
      file_edit: false,

      // Popis soubor
      popis_soubor_znaky: 300,
      nazev_habilitacni_prace: null,
      nazev_oblasti: null,

      //select input
      nazev_oboru: null,
      ford: null,
      domena: null,

      //pridani programu
      datum_odevzdani: null,

      pocet_error: 0,
      e: "",
      ok: "",
      count: 0,
      errors_program: [],
      hodnota2: null,
      hodnota1: null,

      popis_vystupu_vyuziti_znaky: 256,
      popis_vystupu_vyuziti: "",


      // Popis projektu text area validace znaků
      popis_projektu_znaky: 256,
      popis_projektu_text: "",
      beginDate: new Date(),
      jmenohostujicihoprofesora: null,
      odhadovana_cena_navrhu: "",
      popis_navrhu: "",
      hostujici_profesor_nazev: "",
      hostujici_profesor_od: null,
      hostujici_profesor_do: null,
      hostujici_profesor_popis: "",
      hostujici_profesor_cinnost_input: { name_cinnosti: "" },
      hostujici_profesor_cinnost: [],

      nazev_knihy:"",
      nakladatelstvi: "",
      monogramie_datumdo: "",
      monogramie_datu_do: null,




      // datepicker česká lokalizace
      cs: cs,
      ov: true,
      allq: [
        {
          type: 1,
          selected: false,
        },
        {
          type: 2,
          selected: false,
        },
        {
          type: 3,
          selected: false,
        },
      ],
      q: [
        {
          type: 1,
          selected: false,
        },
        {
          type: 2,
          selected: false,
        },
        {
          type: 3,
          selected: false,
        },
      ],
      selectedq: null,
      validace_profesor: null,
      podil: 0.1,
      parametr: null,
      publikace: [],
      program: null,
      casopis: null,
      nazev: null,
      tema: null,
      minpodil: 0.1,
      editPublikace: false,
      editPublikaceId: null,
      qhodnoty: null,
      cena: 0,
      spolupracovnici: [],
      spolupracovnikJmeno: "",
      spolupracovnikPodil: 0,
      podilAutor: 100,
      podilMax: 100,
      messages: [],
      confirm_veda: null,
      zobrazitConfirm: false,
      nazev_tematu: "",
      predmety_nazev: "",

      // kalendáře hostující profesor
      from: new Date(),
      to: new Date(),
      disabledDatesFrom: {
        to: new Date(),
        from: null,
      },
      disabledDatesTo: {
        to: new Date(),
      },
    };
  },

  filters: {
    formatNumber: function (value) {
      return numeral(value).format("0.00");
    },
  },

  methods: {
    validace_input_chat() {
      if (!this.nova_zprava) {
        alert("Prosím vyplňte text zprávy");
        return false;
      }
      return true;
    },

    addMessage() {
      if (this.validace_input_chat()) {
        let data = {
          zprava: this.nova_zprava,
          id: this.id,
          id_uzivatel: this.$store.state.user.id_uzivatel,
        };

      
        this.$http.post("/api/admin/add_zpravu", data).then((response) => {
          if (response.data == "success") {
            this.messages.push({
              id_uzivatele: this.$store.state.user.id_uzivatel,
              id_projekt: this.id,
              content: data.zprava,
            });
            this.nova_zprava = "";
          } else {
            alert("Došlo k chybě číslo 801");
          }
        });
      }
    },

    checkLogin() {
      if (this.$store.state.authenticated) {
        let data = {
          id_user: this.$store.state.user.id_uzivatel,
        };

        this.$http.post("/api/user/reload", data).then((response) => {
          window.console.log(response.data);
          if (response.data == "betterRefresh") {
            this.$store.dispatch("getUser");
          } else if (response.data != "allright") {
            this.$http.get("/api/user/logout").then(() => {
              this.$store.dispatch("logout").then(() => {
                window.localStorage.clear();
                this.$router.push("/login");
              });
            });
          }
        });
      } else {
        this.$http.get("/api/user/logout").then(() => {
          this.$store.dispatch("logout").then(() => {
            window.localStorage.clear();
            this.$router.push("/login");
          });
        });
      }
    },

    soubor_dialog() {
      this.ov = 3;
      this.popis_soubor = "";
    },

    updateDDT(selected) {
      this.disabledDatesTo.to = selected;
      if (this.to < this.disabledDatesTo.to) {
        this.to = this.disabledDatesTo.to;
      }
    },

    updateDDF(selected) {
      this.disabledDatesFrom.from = selected;
      if (this.from < this.disabledDatesTo.from) {
        this.from = this.disabledDatesTo.from;
      }
    },

    keyhandler: function (event) {
      if (event.key === "Backspace" || event.key === "Delete") {
        event.preventDefault();
      }
    },

    showConfirmation_focus() {
      if (this.veda == "") {
        this.confirm_veda = "";
      } else {
        this.confirm_veda = this.veda;
      }
    },

    showConfirmation() {
      if (this.confirm_veda != "") {
        this.zobrazitConfirm = true;
      } else {
        this.errors_program = [];
      }
    },

    cancelSelection() {
      this.veda = this.confirm_veda;
      this.zobrazitConfirm = false;
    },

    confirmSelection() {
      this.zobrazitConfirm = false;
      this.datum_odevzdani = null;
      this.agentura = null;
      this.spolupracovnici = [];
      this.popis_projektu_text = "";
      this.nazev_oblasti = null;
      this.nazev_habilitacni_prace = null;
      this.hostujici_profesor_cinnost = [];
      this.hostujici_profesor_datumdo = null;
      this.hostujici_profesor_datumod = null;
      this.hostujici_profesor_nazev = "";


      this.nazev_knihy = "";
      this.nakladatelstvi = "";
      this.monogramie_datumdo = null; 


      this.publikace = [];
      this.cena = 0;
      this.podilAutor = 100;
      this.podilMax = 100;
      this.volitelne.forEach((v) => {
        v.selected = false;
      });
    },

    submitForm() {
      if (this.validacePridani_souboru()) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("id", this.id);
        formData.append("novy_nazev", this.file_novy_nazev);
        formData.append("popis", this.popis_soubor);

        this.$http
          .post("/api/fileuploader/pridat_soubor", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            window.console.log(response.data);
            if (response.data == "success") {
              this.nacteni_souboru();
              this.ov = 1;
            } else {
              alert("Došlo k chybě číslo 1001");
            }
          });
      }
    },

    upravitForm() {
      if (this.validacePridani_souboru()) {
        let data = {
          id: this.files_id,
          popis: this.popis_soubor,
        };
        this.$http
          .post("/api/fileuploader/upravit_soubor", data)
          .then((response) => {
            window.console.log(response.data);
            if (response.data == "success") {
              this.ov = 1;
            } else {
              alert("Došlo k chybě číslo 1002");
            }
          });
      }
    },

    nacteni_souboru() {
      this.$http
        .get("/api/fileuploader/nacteni_souboru/" + this.id)
        .then((response) => {
          this.files = response.data;
        });
    },

    onChangeFileUpload() {
      this.file = this.$refs.file.files[0];
      this.file_novy_nazev = this.file.name.toLowerCase().split(" ").join("");
      this.file_novy_nazev =
        moment(new Date()).format("YYMMDDhhmmss") + this.file_novy_nazev;
    },

    nacteni_souboru_detail(id) {
      this.ov = 3;
      this.files_id = id;
      this.file_edit = true;

      this.$http
        .get("/api/fileuploader/nacteni_souboru_detail/" + id)
        .then((response) => {
          this.soubor = response.data[0];
          this.popis_soubor = this.soubor.popis;
          this.popis_projektu = this.soubor.popis.length;

          this.file_novy_nazev = this.soubor.nazev_souboru;
        });
    },

    //validace_pridani_souboru
    validacePridani_souboru() {
      this.errors_program = [];
      if (!this.popis_soubor) {
        this.errors_program.push("Vyplňte prosím popis souboru.");
      }
      if (!this.file_novy_nazev) {
        this.errors_program.push("Vložte prosím soubor");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    //validace elearning
    validaceElearning_odeslat() {
      this.errors_program = [];
      if (!this.predmety_nazev) {
        this.errors_program.push("Vyplňte prosím název/názvy předmětů.");
      }
      if (this.popis_vystupu_vyuziti.length <= 0) {
        this.errors_program.push("Uveďte prosím popis předpokládaného výstupu a jeho využití.");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    //ukládání hostujiciho profesora
    validaceHostujici_profesor_odeslat() {
      this.errors_program = [];
      if (!this.hostujici_profesor_nazev) {
        this.errors_program.push("Vyplňte prosím jméno profesora");
      }
      if (!this.hostujici_profesor_datumod) {
        this.errors_program.push("Vyplňte prosím datum od.");
      }
      if (!this.hostujici_profesor_datumdo) {
        this.errors_program.push("Vyplňte prosím datum do.");
      }
      if (this.hostujici_profesor_cinnost.length <= 0) {
        this.errors_program.push("Uveďte prosím činnost.");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },





    //ukládání vědecké monografie
    validace_vedecka_monografie_odeslat() {
      this.errors_program = [];
      if (!this.nazev_knihy) {
        this.errors_program.push("Vyplňte prosím název knihy");
      }

       if (!this.nakladatelstvi) {
        this.errors_program.push("Vyplňte prosím název nakladatelství.");
      }

   
      if (!this.monogramie_datumdo) {
        this.errors_program.push("Vyplňte prosím předpokládaný termín odevzdání.");
      }
 
      if (!this.errors_program.length) {
        return true;
      }
    },





    //validace_publikacni_cinnost
    validacePublikacni_cinnost_odeslat() {
      this.errors_program = [];
      if (this.publikace.length <= 0) {
        this.errors_program.push("Prosím přidejte publikaci.");
      }
      if (this.selectedv == 0) {
        this.errors_program.push("Prosím označte podporu osobního růstu.");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    //validace_parametr
    validaceParametr_odeslat() {
      this.errors_program = [];
      if (this.selectedq == null) {
        this.errors_program.push("Prosím vyplňte hodnotu Q.");
      }
      if (!this.ford) {
        this.errors_program.push("Prosím vyplňte FORD");
      }
      if (!this.domena) {
        this.errors_program.push("Prosím vyplňte doménu");
      }
      if (!this.casopis) {
        this.errors_program.push("Vyplňte prosím název časopisu");
      }
      if (!this.nazev_tematu) {
        this.errors_program.push("Vyplňte prosím název tématu");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    //validace_jmenovaci rizeni
    validaceJmenovaci_rizeni_odeslat() {
      this.errors_program = [];
      if (!this.nazev_oblasti) {
        this.errors_program.push("Vyplňte prosím nazev oboru.");
      }
      if (!this.datum_odevzdani) {
        this.errors_program.push("Vyplňte prosím datum odevzdání.");
      }
      if (this.selectedv == 0) {
        this.errors_program.push("Prosím označte podporu osobního růstu");
      }
      if (this.publikace.length <= 0) {
        this.errors_program.push("Prosím přidejte publikaci");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    //habilitacni rizeni
    validaceHabilitacni_rizeni_odeslat() {
      this.errors_program = [];
      if (this.publikace.length <= 0) {
        this.errors_program.push("Prosím přidejte publikaci");
      }
      if (!this.nazev_habilitacni_prace) {
        this.errors_program.push("Vyplňte prosím název práce.");
      }
      if (!this.datum_odevzdani) {
        this.errors_program.push("Vyplňte prosím datum odevzdání.");
      }
      if (this.selectedv == 0) {
        this.errors_program.push("Prosím označte podporu osobního růstu");
      }
      if (!this.nazev_oblasti) {
        this.errors_program.push("Prosím vyplňte název oboru");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    //Projekt nový a opravený
    validaceProjekt() {
      this.errors_program = [];

/*       if (this.publikace.length <= 0) {
        this.errors_program.push("Prosím přidejte publikaci");
      }
 */

      if (!this.nazev_habilitacni_prace) {
        this.errors_program.push("Vyplňte prosím název práce.");
      }
      if (this.selectedv == 0) {
        this.errors_program.push("Prosím označte podporu osobního růstu");
      }
      if (!this.agentura) {
        this.errors_program.push("Prosím vyplňte agenturu");
      }


      if (!this.popis_projektu_text) {
        this.errors_program.push("Prosím vyplňte popis projektu");
      }



      if (!this.errors_program.length) {
        return true;
      }
    },

    //Validace spolupracovnika
    validaceSpolupracovnika() {
      this.errors_program = [];
      if (!this.spolupracovnikJmeno) {
        this.errors_program.push("Vyplňte prosím jméno spolupracovníka.");
      }
      if (!this.spolupracovnikPodil) {
        this.errors_program.push("Vyplňte prosím podíl spolupracovníka");
      }
      if (this.spolupracovnici.length == 3) {
        this.errors_program.push("Nelze vložit více, nežli 3 spolupracovníky");
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    validateSpolupracovnikPodil() {
      if (this.spolupracovnikPodil < 0) {
        this.spolupracovnikPodil = 0;
      }
      if (this.spolupracovnikPodil > this.podilMax) {
        this.spolupracovnikPodil = this.podilMax;
        alert("Hodnota podílu spolupracovníka nesmí přesáhnout 100%");
      }
    },

    validaceHostujici_Cinnosti: function () {
      this.errors_program = [];
      if (!this.hostujici_profesor_cinnost_input.name_cinnosti) {
        this.errors_program.push(
          "Vyplňte prosím činnost hostujícího profesora."
        );
      }
      if (!this.errors_program.length) {
        return true;
      }
    },

    // formátování data datepicker
    formatovani_datepickeru(val) {
      if (val) {
        this.datum_odevzdani = moment(String(val)).format(
          "YYYY/MM/DD hh:mm:ss"
        );
        return;
      }
    },

    //tlačítka pro přidání činnosti hostujícího profesora
    deleteHostujici_profesor_cinnost: function (idx) {
      this.hostujici_profesor_cinnost.splice(idx, 1);
    },

    addHostujici_profesor_cinnost() {
      if (this.validaceHostujici_Cinnosti()) {
        this.hostujici_profesor_cinnost.push({
          ...this.hostujici_profesor_cinnost_input,
        });
      }
      this.clearInputHostujici_Cinnosti();
    },

    clearInputHostujici_Cinnosti: function () {
      this.hostujici_profesor_cinnost_input.name_cinnosti = "";
    },

    showModal() {
      this.$modal.show("program-modal", {
        height: "auto",
      });
    },

    /* posledni zmena */
    hideModal() {
      setTimeout(
        function () {
          this.$emit("close");
          this.$modal.hide("program-modal");
          var body = document.body;
          body.classList.remove("hideOverflow");
        }.bind(this),
        15
      );
    },

    pridatSpolupracovnika() {
      if (this.validaceSpolupracovnika()) {
        let spolupracovnik = {
          id: uuid.v1(),
          jmeno: this.spolupracovnikJmeno,
          podil: this.spolupracovnikPodil,
        };
        this.spolupracovnici.push(spolupracovnik);

        let podilSp = 0;
        this.spolupracovnici.forEach((sp) => {
          podilSp += Number(sp.podil);
        });

        this.podilAutor -= this.spolupracovnikPodil;
        this.podilMax -= this.spolupracovnikPodil;

        window.console.log(podilSp);
        this.spolupracovnikJmeno = "";
        this.spolupracovnikPodil = 0;
      }
    },

    removeSpolupracovnik(id) {
      this.spolupracovnici
        .filter((sp) => sp.id == id)
        .forEach((sp) => {
          this.podilAutor += Number(sp.podil);
          this.podilMax += Number(sp.podil);
        });

      this.spolupracovnici = this.spolupracovnici.filter((e) => e.id !== id);
    },

    //pouze zpět
    parametr_zpet() {
      this.selectq(null);
      this.casopis = null;
      this.nazev_tematu = null;
      this.tema = null;
      this.ford = null;
      this.domena = null;
      this.podil = 0.1;
      this.ov = 1;

      // pro box ov3
      this.file_edit = false;
      this.popis_soubor = "";
      this.files_id = "";
      this.file_novy_nazev = "";
    },

    spocitatCenu() {
      let cena = 0;
      if (this.publikace) {
        this.publikace.forEach((p) => {
          this.qhodnoty.forEach((q) => {
            if (p.Q == q.id_Q) {
              cena += Number(q.penezni_ohodnoceni) * Number(p.podil);
            }
          });
        });
      }
      this.cena = Number(cena.toFixed(1));
    },

    validateInputs() {
      if (this.veda == 1) {
        if (!this.validacePublikacni_cinnost_odeslat()) {
          this.errors_program.push(
            "Validace publikační činnosti neproběhla správně."
          );
          return false;
        }
      } else if (this.veda == 2) {
        if (!this.validaceJmenovaci_rizeni_odeslat()) {
          this.errors_program.push(
            "Validace jmenovacího řízení neproběhla správně."
          );
          return false;
        }
      } else if (this.veda == 3) {
        if (!this.validaceHabilitacni_rizeni_odeslat()) {
          this.errors_program.push(
            "Validace habilitačního řízení neproběhla správně."
          );
          return false;
        }
      } else if (this.veda == 4) {
        if (!this.validaceHostujici_profesor_odeslat()) {
          this.errors_program.push(
            "Validace hostujícího profesora neproběhla správně."
          );
          return false;
        }
      } else if (this.veda == 7) {
        if (!this.validaceElearning_odeslat()) {
          this.errors_program.push(
            "Validace e-learningu neproběhla správně."
          );
          return false;
        }
      } 
      
      
      else if (this.veda == 8) {
        if (!this.validace_vedecka_monografie_odeslat()) {
          this.errors_program.push(
            "Validace e-learningu neproběhla správně."
          );
          return false;
        }
      } 
      
      
      else if (this.veda == 5 || this.veda == 6) {
        this.errors_program.push("Validace projektu neproběhla správně.");
        if (!this.validaceProjekt()) {
          return false;
        }
      }
      return true;
    },

    sendEdit() {
      let data = {
        id: this.id,
        volitelne: this.volitelne,
        veda: this.veda,
        oblast: this.oblast,
        cena: this.cena,
        nazev_jmeno: this.nazev_habilitacni_prace,
        agentura: this.agentura,
        nazev_oblasti: this.nazev_oblasti,
        hostujici_profesor_nazev: this.hostujici_profesor_nazev,
        datum_od: this.hostujici_profesor_datumod,
        datum_do: this.hostujici_profesor_datumdo,
        datum_odevzdani: this.datum_odevzdani,
        popis_projektu_text: this.popis_projektu_text,
        predmety_nazev: this.predmety_nazev,
        popis_vystupu_vyuziti: this.popis_vystupu_vyuziti,


        nazev_knihy: this.nazev_knihy,
        nakladatelstvi: this.nakladatelstvi,
        monogramie_datumdo: this.monogramie_datumdo,

      };

      if (!this.validateInputs()) {
        return false;
      }

      this.$http.post("/api/program/upravit_program", data).then((response) => {
        if (response.data == "success") {
          if (this.veda == 5 || this.veda == 6) {
            this.pridatSpolupracovniky().then(() => {
              this.pridatParametry().then(() => {
                setTimeout(
                  function () {
                    this.$store.dispatch("getProgramy");
                    this.$emit("close");
                    return true;
                  }.bind(this),
                  10
                );
              });
            });
          } else if (this.veda == 4) {
            this.cinnosti_edit_zapis().then(() => {
              setTimeout(
                function () {
                  this.$store.dispatch("getProgramy");
                  this.$emit("close");
                  return true;
                }.bind(this),
                10
              );
            });
          } else {
            this.pridatParametry().then(() => {
              setTimeout(
                function () {
                  this.$store.dispatch("getProgramy");
                  this.$emit("close");
                  return true;
                }.bind(this),
                10
              );
            });
          }
        } else {

       
          alert("Došlo k chybě číslo 1301");
          return false;
        }
      });
    },

    validaceProgramVeda() {
      if (this.oblast == "" || this.veda == "") {
        this.errors_program = [];
        if (this.oblast == "") {
          this.errors_program.push("Prosím vyplňte oblast");
        }
        if (this.veda == "") {
          this.errors_program.push("Prosím vyplňte program");
        }
        return false;
      }
    },

    confirm() {
      this.$gtag.event("confirm() v ProgramModal", {
        event_category: "Práce s aplikací",
      });

      this.checkLogin();

      this.validaceProgramVeda();

      if (this.edit) {
        this.sendEdit();
      }

      // pridání nového projektu
      else {
        // zápis publikační činnost a jmenovací řízení
        if (this.veda == 1) {
          if (this.validacePublikacni_cinnost_odeslat()) {
            let data = {
              veda: this.veda,
              oblast: this.oblast,
              volitelne: this.volitelne,
              cena: this.cena,
              id_user_login: this.$store.state.user.id_uzivatel,
            };
            this.$http
              .post("/api/program/pridani_publikacni_cinnost", data)
              .then((response) => {
                if (response.data == "success") {
                  this.pridatParametry().then(() => {
                    setTimeout(
                      function () {
                        this.$http.get("/api/email/pridany_projekt/").then(() => {});
                        var body = document.body;
                      body.classList.remove("hideOverflow");
                        this.$store.dispatch("getProgramy");
                        this.$emit("close");
                      }.bind(this),
                      40
                    );
                  });
                } else {
                  alert("Došlo k chybě číslo 1401");
                }
              });
          }
        }

        // zápis jmenovací řízení
        if (this.veda == 2) {
          if (this.validaceJmenovaci_rizeni_odeslat()) {
            let data = {
              veda: this.veda,
              oblast: this.oblast,
              volitelne: this.volitelne,
              cena: this.cena,
              nazev_oblasti: this.nazev_oblasti,
              datum_odevzdani: this.datum_odevzdani,
              id_user_login: this.$store.state.user.id_uzivatel,
            };

            this.$http
              .post("/api/program/pridat_jmenovaci_rizeni", data)
              .then((response) => {
                if (response.data == "success") {
                  this.pridatParametry().then(() => {
                    setTimeout(
                      function () {
                        this.$http.get("/api/email/pridany_projekt/").then(() => {});
                        var body = document.body;
                      body.classList.remove("hideOverflow");
                        this.$store.dispatch("getProgramy");
                        this.$emit("close");
                      }.bind(this),
                      40
                    );
                  });
                } else {
                  alert("Došlo k chybě číslo 1402");
                }
              });
          }
        }

        // zápis habilitačního řízení
        else if (this.veda == 3) {
          if (this.validaceHabilitacni_rizeni_odeslat()) {
            let data = {
              veda: this.veda,
              oblast: this.oblast,
              volitelne: this.volitelne,
              cena: this.cena,
              nazev_habilitacni_prace: this.nazev_habilitacni_prace,
              nazev_oblasti: this.nazev_oblasti,
              datum_odevzdani: this.datum_odevzdani,
              id_user_login: this.$store.state.user.id_uzivatel,
            };

            this.$http
              .post("/api/program/pridat_habilitacni_rizeni", data)
              .then((response) => {
                if (response.data == "success") {
                  this.pridatParametry().then(() => {
                    setTimeout(
                      function () {
                        this.$http.get("/api/email/pridany_projekt/").then(() => {});
                        var body = document.body;
                      body.classList.remove("hideOverflow");
                        this.$store.dispatch("getProgramy");
                        this.$emit("close");
                      }.bind(this),
                      40
                    );
                  });
                } else {
                  alert("Došlo k chybě číslo 1403");
                }
              });
          }
        }

        // zápis hostujicícího profesora
        else if (this.veda == 4) {
          if (this.validaceHostujici_profesor_odeslat()) {
            let data = {
              veda: this.veda,
              oblast: this.oblast,
              nazev_jmeno: this.hostujici_profesor_nazev,
              datum_od: this.hostujici_profesor_datumod,
              datum_do: this.hostujici_profesor_datumdo,
              cinnosti: this.hostujici_profesor_cinnost,
              id_user_login: this.$store.state.user.id_uzivatel,
            };

            this.$http
              .post("/api/program/pridat_hostujiciho_profesora", data)
              .then((response) => {
                if (response.data == "success") {
                  setTimeout(
                    function () {
                      this.$http.get("/api/email/pridany_projekt/").then(() => {});
                      var body = document.body;
                      body.classList.remove("hideOverflow");
                      this.$store.dispatch("getProgramy");
                    }.bind(this),
                    35
                  );

                  setTimeout(
                    function () {
                      this.$emit("close");
                    }.bind(this),
                    40
                  );
                } else {
                  alert("Došlo k chybě číslo 1404");
                }
              });
          }
        }



        // zápis vedeckou monografii
        else if (this.veda == 8) {
          if (this.validace_vedecka_monografie_odeslat()) {
            let data = {
              veda: this.veda,
              oblast: this.oblast,
              nazev_knihy: this.nazev_knihy,
              nakladatelstvi: this.nakladatelstvi,
              monogramie_datumdo: this.monogramie_datumdo,
              id_user_login: this.$store.state.user.id_uzivatel,




            };

            this.$http
              .post("/api/program/pridat_vedeckou_monografii", data)
              .then((response) => {
                if (response.data == "success") {
                  setTimeout(
                    function () {
                      this.$http.get("/api/email/pridany_projekt/").then(() => {});
                      var body = document.body;
                      body.classList.remove("hideOverflow");
                      this.$store.dispatch("getProgramy");
                    }.bind(this),
                    35
                  );

                  setTimeout(
                    function () {
                      this.$emit("close");
                    }.bind(this),
                    40
                  );
                } else {
                  alert("Došlo k chybě číslo 1404");
                }
              });
          }
        }






        // zápis projektu nový a opravný
        else if (this.veda == 5 || this.veda == 6) {
          if (this.validaceProjekt()) {
            let data = {
              nazev_jmeno: this.hostujici_profesor_nazev,
              veda: this.veda,
              oblast: this.oblast,
              volitelne: this.volitelne,
              cena: this.cena,
              nazev_habilitacni_prace: this.nazev_habilitacni_prace,
              agentura: this.agentura,
              popis_projektu_text: this.popis_projektu_text,
              id_user_login: this.$store.state.user.id_uzivatel,
            };

            this.$http
              .post("/api/program/pridat_projekt", data)
              .then((response) => {
                if (response.data == "success") {
                  this.pridatParametry().then(() => {
                    this.pridatSpolupracovniky().then(() => {
                      setTimeout(
                        function () {
                          this.$http.get("/api/email/pridany_projekt/").then(() => {});
                          var body = document.body;
                      body.classList.remove("hideOverflow");
                          this.$store.dispatch("getProgramy");
                          this.$emit("close");
                        }.bind(this),
                        10
                      );
                    });
                  });
                } else {
                  alert("Došlo k chybě číslo 1405");
                }
              });
          }
        }

        // zápis elearningu
        else if (this.veda == 7) {
          if (this.validaceElearning_odeslat()) {
            let data = {
              veda: this.veda,
              oblast: this.oblast,
              predmety_nazev: this.predmety_nazev,
              popis_vystupu_vyuziti: this.popis_vystupu_vyuziti,
              id_user_login: this.$store.state.user.id_uzivatel,
            };

            this.$http
              .post("/api/program/pridat_elearning", data)
              .then((response) => {
                if (response.data == "success") {
                  setTimeout(
                    function () {
                      this.$http.get("/api/email/pridany_projekt/").then(() => {});
                      var body = document.body;
                      body.classList.remove("hideOverflow");
                      this.$store.dispatch("getProgramy");
                    }.bind(this),
                    35
                  );

                  setTimeout(
                    function () {
                      this.$emit("close");
                    }.bind(this),
                    40
                  );
                } else {
                  alert("Došlo k chybě číslo 1404");
                }
              });
          }
        }

        //this.$http.get("/api/email/pridany_projekt/").then(() => {});
      }
    },

    async pridatParametry() {
      let data = { publikace: this.publikace };
      this.$http.post("/api/parametr/add", data).then(() => {
        if (
          typeof this.volitelne[5].selected != "undefined" &&
          this.volitelne[5].selected == true
        ) {
          let navrh_data;
          if (this.edit) {
            navrh_data = {
              odhadovana_cena_navrhu: this.odhadovana_cena_navrhu,
              popis_navrhu: this.popis_navrhu,
              id: this.id,
            };
          } else {
            navrh_data = {
              odhadovana_cena_navrhu: this.odhadovana_cena_navrhu,
              popis_navrhu: this.popis_navrhu,
              id: 0,
            };
          }

          this.$http
            .post("/api/parametr/navrh_vlastniho_add", navrh_data)
            .then(() => {
              this.ov = 2;
              this.getParametr().then(() => {
                return true;
              });
            });
        }

        this.ov = 2;
        this.getParametr().then(() => {
          return true;
        });
      });
    },

    // načíst parametr u modalu publikace
    async getParametr() {
      this.$http.get("/api/parametr/get/" + this.id).then((response) => {
        this.parametr = response.data;
        this.parametr.forEach((enter) => {
          this.publikace.push({
            id_parametr: enter.id_parametr,
            Q: enter.Q,
            casopis: enter.casopis,
            ford: enter.ford,
            domena: enter.domena,
            nazev_tematu: enter.tema,
            podil: enter.autorsky_podil,
            financni_ohodnoceni: enter.financni_ohodnoceni,
          });
        });
        this.spocitatCenu();
        return true;
      });
    },

    // odstranění publikace
    removePub(id) {
      this.publikace = this.publikace.filter((p) => p.id_parametr !== id);
      /*window.console.log(this.publikace);
            this.publikace.splice(id, 1);*/
      this.spocitatCenu();
    },

    // cinnosti při editaci
    async cinnosti_edit_zapis() {
      this.$http
        .post("/api/program/edit_cinnosti_zapis/", {
          id: this.id,
          cinnosti: this.hostujici_profesor_cinnost,
        })
        .then(() => {
          return true;
        });
    },

    //přidat a editovat publikaci
    pridatP() {
      if (this.validaceParametr_odeslat()) {
        if (this.selectedq) {
          // nacteni hodnoty
          let hodcena_nezaokrouhlo = 0;
          this.qhodnoty.forEach((q) => {
            if (this.selectedq == q.id_Q) {
              hodcena_nezaokrouhlo +=
                Number(q.penezni_ohodnoceni) * Number(this.podil);
            }
          });
          let hodcena = Number(hodcena_nezaokrouhlo.toFixed(1));

          //editace
          if (this.editPublikace) {
            let p = {
              financni_ohodnoceni: hodcena,
              Q: this.selectedq,
              podil: this.podil,
              tema: this.tema,
              casopis: this.casopis,
              nazev_tematu: this.nazev_tematu,
              ford: this.ford,
              domena: this.domena,
              id_parametr: uuid.v1(),
            };

            let index = this.publikace
              .map(function (p) {
                return p.id_parametr;
              })
              .indexOf(this.editPublikaceId);

            this.publikace[index] = p;
            this.editPublikace = false;
            this.editPublikaceId = null;
          }

          //pridavani
          else {
            this.publikace.push({
              id_parametr: uuid.v1(),
              Q: this.selectedq,
              casopis: this.casopis,
              ford: this.ford,
              domena: this.domena,
              nazev_tematu: this.nazev_tematu,
              podil: this.podil,
              financni_ohodnoceni: hodcena,
            });
          }
        }

        this.selectq(null);
        this.casopis = null;
        this.nazev_tematu = null;
        this.tema = null;
        this.ford = null;
        this.domena = null;
        this.podil = 0.1;
        this.ov = 1;
        this.spocitatCenu();
      }
    },

    // upravení publikace
    editPub(publikace) {
      this.editPublikace = true;
      this.editPublikaceId = publikace.id_parametr;

      this.ov = 2;
      if (publikace.q) {
        this.selectq(publikace.q);
      } else {
        this.selectq(publikace.Q);
      }
      this.nazev_tematu = publikace.nazev_tematu;
      this.casopis = publikace.casopis;
      this.ford = publikace.ford;
      this.domena = publikace.domena;
      this.podil = publikace.podil;
      this.spocitatCenu();
    },

    /*
        // Odstranení publikace
        removeP(id) {
            this.$http
            .post("/api/parametr/remove", {id: id})
            .then((response) => {
                window.console.log(response.data)
            })
            this.getParametr()
            this.spocitatCenu()
        },
        */

    async pridatSpolupracovniky() {
      this.$http
        .post("/api/program/addspolupracovnici", {
          id: this.id,
          spolupracovnici: this.spolupracovnici,
        })
        .then(() => {
          return true;
        });
    },

    // select Q
    selectq(type) {
      this.q.map((i) => {
        if (i.selected && i.type != type) {
          i.selected = false;
        }
        if (!i.selected && i.type == type) {
          i.selected = true;
        }
      });
      if (type != this.selectedq) {
        this.selectedq = type;
      } else {
        this.selectedq = null;
      }
    },
  },

  mounted() {
    this.checkLogin();
    this.showModal();
    var body = document.body;
    body.classList.add("hideOverflow");

    this.$gtag.event("Otevření ProgramModal", {
      event_category: "Práce s aplikací",
    });

    this.publikace = [];

    this.$http
      .get("/api/veda/all/" + this.$store.state.user.id_role)
      .then((response) => {
        this.vedy = response.data;

        this.$http.get("/api/agentura/all").then((response) => {
          this.agentury = response.data;

          this.$http.get("/api/volitelne/all").then((response) => {
            response.data.forEach((element) => {
              element.selected = false;
            });
            this.volitelne = response.data;

            this.$http
              .get("/api/oblast/all")
              .then((response) => {
                this.oblasti = response.data;
              })
              .then(() => {
                this.$http.get("/api/q/hodnoty").then((response) => {
                  this.qhodnoty = response.data;

                  if (this.edit) {
                    this.getParametr().then(() => {
                      this.$http
                        .post("/api/program/getspolupracovnici", this.id)
                        .then((response) => {
                          this.spolupracovnici = response.data;
                          this.spolupracovnici.forEach((sp) => {
                            sp.id = uuid.v1();
                            this.podilAutor -= sp.podil;
                            this.podilMax -= sp.podil;
                          });
                          this.spolupracovnici = response.data;

                          this.$http
                            .get("/api/program/id/" + this.id)
                            .then((response) => {
                              this.program = response.data[0];
                              this.oblast = this.program.id_oblast;
                              this.veda = this.program.id_veda;

                              // nacteni jmenovaciho rizeni
                              if (this.veda == 1) {
                                this.cena = this.program.financni_hodnota;
                              }

                              // nacteni jmenovaciho rizeni
                              if (this.veda == 2) {
                                (this.datum_odevzdani = this.program.datum_do),
                                  (this.nazev_oblasti = this.program.specifikace_ciselnik);
                                this.cena = this.program.financni_hodnota;
                              }

                              // nacteni habilitacniho rizeni
                              if (this.veda == 3) {
                                (this.nazev_habilitacni_prace = this.program.nazev_jmeno),
                                  (this.datum_odevzdani = this.program.datum_do),
                                  (this.nazev_oblasti = this.program.specifikace_ciselnik);
                                this.cena = this.program.financni_hodnota;
                              }

                              // nacteni hostujiciho profesora
                              if (this.veda == 4) {
                                (this.hostujici_profesor_nazev = this.program.nazev_jmeno),
                                  (this.hostujici_profesor_datumod = this.program.datum_od),
                                  (this.hostujici_profesor_datumdo = this.program.datum_do),
                                  this.$http
                                    .get(
                                      "/api/program/edit_cinnosti/" + this.id
                                    )
                                    .then((response) => {
                                      this.zprava = response.data;
                                      this.zprava.forEach((enter) => {
                                        this.hostujici_profesor_cinnost.push({
                                          name_cinnosti: enter.cinnost,
                                        });
                                      });
                                    });
                                    this.cena = this.program.financni_hodnota;
                              }

                            // Vědecká monografie
                              if (this.veda == 8) {
                                (this.nazev_knihy = this.program.nazev_jmeno),
                                (this.nakladatelstvi = this.program.popis),
                                (this.monogramie_datumdo = this.program.datum_od),
                                (this.cena = this.financni_hodnota);
                              }


                              // nacteni projektu
                              if (this.veda == 5 || this.veda == 6) {
                                (this.popis_projektu_text = this.program.popis),
                                  (this.agentura = this.program.specifikace_ciselnik),
                                  (this.nazev_habilitacni_prace = this.program.nazev_jmeno),
                                  (this.cena = this.program.financni_hodnota);
                              }

                              if (
                                this.program.status == 4 ||
                                this.program.status == 2 ||
                                this.historicky
                              ) {
                                this.disabled = true;
                              }
                            })
                            .then(() => {
                              this.$http
                                .get("/api/volitelne/program/" + this.id)
                                .then((response) => {
                                  window.console.log(response.data);
                                  response.data.forEach((selected) => {
                                    this.volitelne.forEach((v) => {
                                      if (v.id == selected.id_checkboxu) {
                                        window.console.log(response.data);
                                        v.selected = true;
                                      }
                                    });
                                  });
                                  if (
                                    typeof this.volitelne[5].selected !=
                                      "undefined" &&
                                    this.volitelne[5].selected == true
                                  ) {
                                    this.$http
                                      .get(
                                        "/api/parametr/navrh_vlsatniho_get/" +
                                          this.id
                                      )
                                      .then((response) => {
                                        this.popis_navrhu =
                                          response.data[0].popis;
                                        this.odhadovana_cena_navrhu =
                                          response.data[0].odhadovana_cena;

                                        return true;
                                      });
                                  }
                                });
                            });
                        });
                    });
                  }

                  this.$http
                    .get("/api/admin/modal_zpravy/" + this.id)
                    .then((response) => {
                      this.zprava = response.data;
                      this.zprava.forEach((enter) => {
                        this.messages.push({
                          id_uzivatele: enter.id_uzivatel,
                          id_projekt: this.id,
                          content: enter.zprava,
                          cele_jmeno: enter.cele_jmeno,
                        });
                      });

                      this.$http
                        .get("/api/fileuploader/nacteni_souboru/" + this.id)
                        .then((response) => {
                          this.files = response.data;

                          this.$http
                            .get("/api/nazevoboru/all")
                            .then((response) => {
                              this.nazevoboru = response.data;

                              this.$http
                                .get("/api/ford/all")
                                .then((response) => {
                                  this.fordy = response.data;
                                  this.$http
                                      .get("/api/domena/all")
                                      .then((response) => {
                                        this.domeny = response.data;
                                        this.nacteno = true;
                                      });
                                });
                            });
                        });
                    });
                });
              });
          });
        });
      });
  },

  watch: {
    veda(val) {
      if (val != 1 && val != 5 && val != 6) {
        this.q = this.allq.filter((q) => q.type != 3);
        this.minpodil = 0.5;
        this.podil = 0.5;
      } else {
        this.q = this.allq;
        this.minpodil = 0.1;
        this.podil = 0.1;
      }
    },
  },

  computed: {
    // pocitani zaskrtlých checkboxu
    selectedv() {
      let selected = 0;
      this.volitelne.forEach((v) => {
        if (typeof v.selected != "undefined" && v.selected) {
          selected++;
        }
      });
      return selected;
    },
  },
};
</script>